import { showModalActionType, hideModalActionType, showCreateModalActionType, UserReducerState, setUserActionType, logoutUserActionType } from './../types/types';
import {
    changeDictionaryActionType,
    changeLanguageActionType,
    changeThemeActionType,
    dictionary, fetchDictionaryActionType,
    language,
    theme
} from "../types/types";

export const ACTIONS = Object.freeze({
    //normal actions
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    CHANGE_THEME: "CHANGE_THEME",
    CHANGE_DICTIONARY: "CHANGE_DICTIONARY",
    SHOW_MODAL:'SHOW_MODAL',
    HIDE_MODAL:'HIDE_MODAL',
    //async actions
    FETCH_DICTIONARY: "FETCH_DICTIONARY",
})
export const changeLanguageAction = (payload: language): changeLanguageActionType => {
    return {
        type: "CHANGE_LANGUAGE",
        payload
    }
}
export const changeThemeAction = (payload: theme): changeThemeActionType => {
    return {
        type: "CHANGE_THEME",
        payload
    }
}
export const changeDictionaryAction = (payload: dictionary): changeDictionaryActionType => {
    return {
        type: "CHANGE_DICTIONARY",
        payload
    }
}
export const fetchDictionaryAction = (payload: language): fetchDictionaryActionType => {
    return {
        type:"FETCH_DICTIONARY",
        payload
    }
}
export const showModalAction = (payload: JSX.Element|null): showModalActionType => {
    return {
        type: "SHOW_MODAL",
        payload
    }
}
export const showCreateModalAction = (payload: JSX.Element|null): showCreateModalActionType => {
    return {
        type: "SHOW_CREATE_MODAL",
        payload
    }
}
export const hideModalAction = (): hideModalActionType => {
    return {
        type: "HIDE_MODAL",
    }
}
//#region User Actions
export const setUserAction = (payload:UserReducerState):setUserActionType => {
    return {
        type: 'SET_USER',
        payload
    }
}
export const logoutUserAction = ():logoutUserActionType => {
    return {
        type: 'LOGOUT_USER'
    }
}
//#endregion

import styled, {css} from "styled-components";
import {MEDIA_DIAMOND, MEDIA_GOLD, MEDIA_PLATINUM, MEDIA_SILVER} from "../../styles/responsive";
import {ComponentPropsWithRef} from "react";
import HorizontalLogo from "../logo/HorizontalLogo";
import { COLORS } from "../../common/constants";

const Bronze = css`
& .vs-large{
    display: block;
    & > svg{
        width: 240px;
        height: 40px;
        margin-bottom: 40px;
        & .pathOne{
            stroke: ${COLORS.PRIMARY.d5};
            fill: ${COLORS.PRIMARY.d5};
        }
        & .pathTwo{
            stroke: ${COLORS.GRAY.d1};
            fill: ${COLORS.GRAY.d1};
        }
        
    }
    & .vs-welcome-message{
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 20px;
    }
}
[dir = 'ltr'] & {

}

[dir = 'rtl'] & {

}

`

const Silver = css`

`

const Gold = css`

`

const Platinum = css`

`

const Diamond = css`

`
type formWelcomeMessageProps = {} & ComponentPropsWithRef<"div">;

const SFormWelcomeMessage = styled.div`
${Bronze}
${MEDIA_SILVER`${Silver}`}
${MEDIA_GOLD`${Gold}`}
${MEDIA_PLATINUM`${Platinum}`}
${MEDIA_DIAMOND`${Diamond}`}
`

export const FormWelcomeMessage = ({children,...props}:formWelcomeMessageProps) => {
return (
<SFormWelcomeMessage {...props}>
    <div className="vs-large">
        <HorizontalLogo />
        <div className="vs-welcome-message">
            Welcome to vu-space
        </div>
    </div>
</SFormWelcomeMessage>
)
}
import styled, {css} from "styled-components";
import {MEDIA_DIAMOND, MEDIA_GOLD, MEDIA_PLATINUM, MEDIA_SILVER} from "../../styles/responsive";
import {ComponentPropsWithRef} from "react";
import {COLORS} from "../../common/constants";

const Bronze = css`
  --size: 90px;
  --animation-duration: 1000ms;
  //--clr1: #bbbb88;
  --clr1: ${COLORS.PRIMARY.d1};
  --clr2: ${COLORS.PRIMARY.d2};
  --clr3: ${COLORS.PRIMARY.d3};
  --clr4: ${COLORS.PRIMARY.d4};
  --clr5: ${COLORS.PRIMARY.d5};

  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;

  & .vs-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.BACKGROUND.d2};
    opacity: 0.8;
  }

  & .vs-spinner {
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    & .vs-spinner-item {
      height: 40%;
      background-color: var(--clr-spinner);
      width: calc(var(--size) / 13);
      animation: spinner5 var(--animation-duration) ease-in-out infinite;
      @keyframes spinner5 {
        25% {
          transform: scaleY(2);
        }

        50% {
          transform: scaleY(1);
        }
      }

      &:nth-child(1) {
        --clr-spinner: var(--clr1);
      }

      &:nth-child(2) {
        --clr-spinner: var(--clr3);
        animation-delay: calc(var(--animation-duration) / 10);
      }

      &:nth-child(3) {
        --clr-spinner: var(--clr5);
        animation-delay: calc(var(--animation-duration) / 10 * 2);
      }

      &:nth-child(4) {
        --clr-spinner: var(--clr4);
        animation-delay: calc(var(--animation-duration) / 10 * 3);
      }

      &:nth-child(5) {
        --clr-spinner: var(--clr2);
        animation-delay: calc(var(--animation-duration) / 10 * 4);
      }
    }
  }

  [dir = 'ltr'] & {

  }

  [dir = 'rtl'] & {

  }

`

const Silver = css`

`

const Gold = css`

`

const Platinum = css`

`

const Diamond = css`

`
type loadingDashBoardProps = {} & ComponentPropsWithRef<"div">;

const SLoadingDashBoard = styled.div`
  ${Bronze}
  ${MEDIA_SILVER`${Silver}`}
  ${MEDIA_GOLD`${Gold}`}
  ${MEDIA_PLATINUM`${Platinum}`}
  ${MEDIA_DIAMOND`${Diamond}`}
`

export const LoadingDashBoard = ({children, ...props}: loadingDashBoardProps) => {
	return (
		<SLoadingDashBoard>
			<div className="vs-bg"/>
			<div className="vs-spinner">
				<div className="vs-spinner-item"/>
				<div className="vs-spinner-item"/>
				<div className="vs-spinner-item"/>
				<div className="vs-spinner-item"/>
				<div className="vs-spinner-item"/>
			</div>
		</SLoadingDashBoard>
	)
}
import {lazy, Suspense} from 'react';
import {Route, Switch} from "react-router-dom";
import { LoadingDashBoard } from '../components/loading-component/LoadingDashBoard';

//test
const HomePage = lazy(() => import('../pages/home/HomePage'));
const SignPage = lazy(() => import('../pages/sign/SignPage'));
const SettingsPage = lazy(() => import('../pages/settings/SettingsPage'));
const SignUpSuccessPage = lazy(() => import('../pages/sign-up-success/SignUpSuccessPage'));
const ServerErrorPage = lazy(() => import('../pages/server-error/ServerErrorPage'));
const ChangePasswordSuccessPage = lazy(() => import('../pages/change-password-success/ChangePasswordSuccessPage'));
const SavedPropertyPage = lazy(() => import('../pages/saved-property/SavedPropertyPage'));
const EditAccountPage = lazy(() => import('../pages/edit-account/EditAccountPage'));
const MadhoodPage = lazy(() => import('../pages/madhood/MadhoodPage'));
const MarozizPage = lazy(() => import('../pages/maroziz/MarozizPage'));
const ShalabyPage = lazy(() => import('../pages/shalaby/ShalabyPage'));
const YoussefPage = lazy(() => import('../pages/youssef/YoussefPage'));
const ProjectsPage = lazy(() => import('../pages/projects-list/ProjectsListPage'));
const DevelopersListPage = lazy(() => import('../pages/developers-list/DevelopersListPage'));
const UsersPage = lazy(() => import('../pages/users-list/UsersPage'));
const ManageUserPage = lazy(() => import('../pages/manage-user/ManageUserPage'));
const PropertyPage = lazy(() => import('../pages/property/PropertyPage'));
const ModulesPage = lazy(() => import('../pages/modules/ModulesPage'));
const DeveloperPage = lazy(() => import('../pages/developer/DeveloperPage'));
const ManageDeveloperPage = lazy(() => import('../pages/edit-developer/EditDeveloperPage'));
const EditPropertyPage = lazy(() => import('../pages/wizards/edit-property/EditPropertyPage'));
const EditProjectPage = lazy(() => import('../pages/wizards/edit-project/EditProjectPage'));
const ProjectPage = lazy(() => import('../pages/project/ProjectPage'));
const PropertiesListPage = lazy(() => import('../pages/properties-list/PropertiesListPage'));
const LocationsPage = lazy(() => import('../pages/locations/LocationsPage'));
const FinishingsPage = lazy(() => import('../pages/finishings/FinishingsPage'));
const ServicesPage = lazy(() => import('../pages/services/ServicesPage'));
const LeadsPage = lazy(() => import('../pages/leads/LeadsPage'));
const NotificationsPage = lazy(() => import('../pages/notifications/NotificationsPage'));

const Routes = () => (
	<Suspense fallback={<LoadingDashBoard/>}>
		<Switch>
			<Route exact path='/' component={HomePage}/>
			<Route exact path='/sign' component={ SignPage }/>
			<Route exact path='/settings' component={ SettingsPage }/>
			<Route exact path='/sign-up-success' component={ SignUpSuccessPage }/>
			<Route exact path='/server-error' component={ ServerErrorPage }/>
			<Route exact path='/change-password-success' component={ ChangePasswordSuccessPage }/>
			<Route exact path='/saved-property' component={ SavedPropertyPage }/>
			<Route exact path='/edit-account' component={ EditAccountPage }/>
			<Route exact path='/madhood' component={ MadhoodPage }/>
			<Route exact path='/maroziz' component={ MarozizPage }/>
			<Route exact path='/shalaby' component={ ShalabyPage }/>
			<Route exact path='/youssef/:id?' component={ YoussefPage }/>
			<Route exact path='/projects' component={ ProjectsPage }/>
			<Route exact path='/developers' component={ DevelopersListPage }/>
			<Route exact path='/properties' component={ PropertiesListPage }/>
			<Route exact path='/users' component={ UsersPage }/>
			<Route exact path='/leads' component={ LeadsPage }/>
			<Route exact path='/property/:id' component={ PropertyPage }/>
			<Route exact path='/add-user' component={ ManageUserPage }/>
			<Route exact path='/project/:id' component={ ProjectPage }/>
			<Route exact path='/services' component={ ServicesPage }/>
			<Route exact path='/manage-user/:id?' component={ ManageUserPage }/>
			<Route exact path='/modules' component={ ModulesPage }/>
			<Route exact path='/notifications' component={ NotificationsPage }/>
			<Route exact path='/locations' component={ LocationsPage }/>
			<Route exact path='/finishings' component={ FinishingsPage }/>
			<Route exact path='/developer/:id' component={ DeveloperPage }/>
			<Route exact path='/edit-developer/:id' component={ ManageDeveloperPage }/>
			<Route path='/edit-property/:id?' component={ EditPropertyPage }/>
			<Route path='/edit-project/:id?' component={ EditProjectPage }/>
		</Switch>
	</Suspense>
);

export default Routes;
import {ComponentPropsWithRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled, {css} from "styled-components";
import {COLORS} from "../../common/constants";
import {hideModalAction} from "../../store/actions";
import {MEDIA_DIAMOND, MEDIA_GOLD, MEDIA_PLATINUM, MEDIA_SILVER} from "../../styles/responsive";
import {ReduxState} from "../../types/types";

const CSSCreate = css`
    /* & .vs-modal-close{
   position:absolute;
   top:25px;
   right:10px;
   left:auto;
   width:fit-content;
   color:${COLORS.GRAY.d2};
   background-color: transparent;
   border:0px;
   font-size:24px;
   cursor:pointer
 } */

  & .modal-wrapper {
    position: absolute;
    user-select: none;
    background-color: ${COLORS.BACKGROUND.d2};
    border: 1px solid ${COLORS.GRAY.d2};
    border-radius: 5px;
    top: 50%;
    left: 50%;
    padding: 0;
    transform: translate(-50%, -50%);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: ${COLORS.BACKGROUND.d2};
  }
`;

const CSSShowModal = css`
  display: block;
`;

const Bronze = css`
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index:100;

  & .vs-modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    left: auto;
    width: fit-content;
    color: ${COLORS.GRAY.d2};
    background-color: transparent;
    border: 0px;
    font-size: 24px;
    cursor: pointer
  }

  & .modal-wrapper {
    position: absolute;
    user-select: none;
    background-color: ${COLORS.BACKGROUND.d1};
    border-radius: 5px;
    top: 50%;
    left: 50%;
    padding: 40px 20px;
    transform: translate(-50%, -50%);
    &:before{
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 5px;
      border: 2px solid ${COLORS.GRAY.d1};
      opacity: 0.1;
      top: 0;
      left: 0;
      z-index:-1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: ${COLORS.BACKGROUND.d2};
  }

  [dir = 'ltr'] & {
  }

  [dir = 'rtl'] & {
  }

  ${({isActive}: sModalProps) => isActive && CSSShowModal}
  ${({variant}: sModalProps) => variant === 'create' && CSSCreate}
`
const Silver = css`

`

const Gold = css`

`

const Platinum = css`

`

const Diamond = css`

`
type modalProps = ComponentPropsWithRef<"div">;

type sModalProps = {
	isActive?: boolean
	variant: string
} & ComponentPropsWithRef<'div'>;

const SModal = styled.div<sModalProps>`
  ${Bronze}
  ${MEDIA_SILVER`${Silver}`}
  ${MEDIA_GOLD`${Gold}`}
  ${MEDIA_PLATINUM`${Platinum}`}
  ${MEDIA_DIAMOND`${Diamond}`}
`
export const Modal = ({children, ...props}: modalProps) => {
	const {isActive, content, variant} = useSelector(({ui}: ReduxState) => ui.modal);
	const dispatch = useDispatch()
	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.target === e.currentTarget && dispatch(hideModalAction());
	return (
		<SModal isActive={isActive} variant={variant} onClick={handleClick} {...props}>
			<div className="vs-modal-close" onClick={() => dispatch(hideModalAction())}>✕</div>
			<div className="modal-wrapper">
				{content}
			</div>
		</SModal>
	);
}
import {ACTIONS} from "../actions";
import {changeDictionaryActionType, dictionary} from "../../types/types";

//Todo get initial state from local storage or from the server

const defaultDictionary = {}

export const dictionaryReducer = (dictionary:dictionary = defaultDictionary, action:changeDictionaryActionType) => {
	if (action.type === ACTIONS.CHANGE_DICTIONARY) {
		return action.payload;
	}
	return dictionary;
}
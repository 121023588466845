import { boolean } from 'joi';
import { showModalActionType, hideModalActionType, showCreateModalActionType } from '../../types/types';

//Todo get initial state from local storage or from the server

const defaultUiState = {
    modal:{
        isActive:false,
        content:null,
        variant:false,
    }
}

export const uiReducer = (uiState = defaultUiState, action:showModalActionType|hideModalActionType|showCreateModalActionType) => {
    if (action.type === 'SHOW_MODAL') {
        return {
            ...uiState,
            modal:{
                isActive:true,
                content:action.payload,
                variant: 'normal',

            }
        }
    }
    if (action.type === 'SHOW_CREATE_MODAL') {
        return {
            ...uiState,
            modal:{
                isActive:true,
                content:action.payload,
                variant: 'create',

            }
        }
    }
    if (action.type === 'HIDE_MODAL') {
        return {
            ...uiState,
            modal:{
                isActive:false,
                content:null
            }
        }
    }

return uiState;
}
import { ComponentPropsWithRef, MouseEvent, useRef } from "react";
import styled, { css } from "styled-components";
import { MEDIA_DIAMOND, MEDIA_GOLD, MEDIA_PLATINUM, MEDIA_SILVER } from "../../../styles/responsive";
import { COLORS } from "../../../common/constants";

const Bronze = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  background-color: transparent;
  --time: ${({time}:buttonRipplesProps) => `${time}ms`};
  --opacity: 0.15;
  --diameter: 800px; 
  & span {
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    background-color: ${({color}: buttonRipplesProps) => color};
    border-radius: 50%;
    opacity: var(--opacity);
    animation: ripple calc(var(--time) * 5 / 6) ease-out forwards, fade var(--time) ease-out forwards;
    transform: translateX(-50%) translateY(-50%);

    @keyframes ripple {
      0% {
        width: 16px;
        height: 16px;
      }
      80% {
        width: var(--diameter);
        height: var(--diameter);
      }
      100% {
        width: var(--diameter);
        height: var(--diameter);
        opacity: 0;
      }
    }

    @keyframes fade {
      0% {
        opacity: var(--opacity);
      }
      100% {
        opacity: 0;
      }
    }

  }

  [dir = 'ltr'] & {

  }

  [dir = 'rtl'] & {

  }

`

const Silver = css`

`

const Gold = css`

`

const Platinum = css`

`

const Diamond = css`

`
type buttonRipplesProps = { color?: string, time?: number } & ComponentPropsWithRef<"div">;

const SButtonRipples = styled.div<buttonRipplesProps>`
  ${Bronze}
  ${MEDIA_SILVER`${Silver}`}
  ${MEDIA_GOLD`${Gold}`}
  ${MEDIA_PLATINUM`${Platinum}`}
  ${MEDIA_DIAMOND`${Diamond}`}
`

export const ButtonRipples = styled(({color=COLORS.TEXT, time = 1500, ...props}: buttonRipplesProps) => {
    const ripplesWrapper = useRef<HTMLDivElement>(null)
    const onClickHandler = (e: { target: HTMLElement } & MouseEvent<HTMLDivElement>) => {
        const {top, left} =
        (ripplesWrapper && ripplesWrapper.current && ripplesWrapper.current.getBoundingClientRect())
        || {top: 0, left: 0};
        let ripple: HTMLSpanElement | null = document.createElement("span");
        ripple.style.top = `${e.clientY - top}px`;
        ripple.style.left = `${e.clientX - left}px`;
        ripplesWrapper && ripplesWrapper.current && ripplesWrapper.current.appendChild(ripple);
        setTimeout(() => {
            ripple && ripple.remove();
            ripple = null;
        }, time)
    }
    return (
        <SButtonRipples ref={ripplesWrapper} time={time} onClick={onClickHandler} color={color}/>
    )
})``
import {combineReducers} from "redux";
import {preferencesReducer} from "./preferences-reducer";
import {dictionaryReducer} from "./dictionary-reducer";
import {uiReducer} from "./ui-reducer";
import {propertyReducer} from "./property-reducer";
import { projectReducer } from "./project-reducer";
import { developerReducer } from "./developer-reducer";
import { userReducer } from "./user-reducer";

export const reducers = combineReducers({
	preferences: preferencesReducer,
	dictionary: dictionaryReducer,
	ui: uiReducer,
	property: propertyReducer,
	project: projectReducer,
	developer: developerReducer,
	user:userReducer,
})
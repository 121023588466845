import {call, put} from 'redux-saga/effects'
import {changeDictionaryAction, fetchDictionaryAction} from "../actions";
import {Api} from "../../api/vuSpaceApi";
import {changeLanguageActionType, dictionary, fetchDictionaryActionType} from "../../types/types";

//saga workers
export function* fetchDictionaryWorker(action:fetchDictionaryActionType){
	const dic:dictionary = yield call(Api.getDictionary, action.payload);
	yield put(changeDictionaryAction(dic));
}

export function* langWorker(action:changeLanguageActionType){
	yield put(fetchDictionaryAction(action.payload));
}
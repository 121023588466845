import {css} from "styled-components";
import {COLORS, CSS_VARS, THEMES} from "../common/constants";

const DarkThemeColors = css`
  ${CSS_VARS.COLORS.PRIMARY.d1}: #D0FBE9;
  ${CSS_VARS.COLORS.PRIMARY.d2}: #A2F7D2;
  ${CSS_VARS.COLORS.PRIMARY.d3}: #73F3BC;
  ${CSS_VARS.COLORS.PRIMARY.d4}: #45EFA5;
  ${CSS_VARS.COLORS.PRIMARY.d5}: #16EB8F;
  ${CSS_VARS.COLORS.BACKGROUND.d1}: #12303C;
  ${CSS_VARS.COLORS.BACKGROUND.d2}: #07131C;
  ${CSS_VARS.COLORS.BACKGROUND.d3}: #000000;
  ${CSS_VARS.COLORS.BACKGROUND.gradient}: transparent linear-gradient(180deg, ${COLORS.BACKGROUND.d3} 0%, ${COLORS.BACKGROUND.d1} 100%);
  ${CSS_VARS.COLORS.GRAY.d1}: #CACCD5;
  ${CSS_VARS.COLORS.GRAY.d2}: #838996;
  ${CSS_VARS.COLORS.WARNING}: #FEC132;
  ${CSS_VARS.COLORS.INFO}: #58C2D9;
  ${CSS_VARS.COLORS.DANGER}: #E64965;
  ${CSS_VARS.COLORS.SUCCESS}: #58D96D;
  ${CSS_VARS.COLORS.TEXT}: #FFFFFF;
`
const LightThemeColors = css`
  ${CSS_VARS.COLORS.PRIMARY.d1}: #D0FBE8;
  ${CSS_VARS.COLORS.PRIMARY.d2}: #A2F7D2;
  ${CSS_VARS.COLORS.PRIMARY.d3}: #73F3BC;
  ${CSS_VARS.COLORS.PRIMARY.d4}: #45EFA5;
  ${CSS_VARS.COLORS.PRIMARY.d5}: #16EB8F;
  ${CSS_VARS.COLORS.BACKGROUND.d1}: #12303C;
  ${CSS_VARS.COLORS.BACKGROUND.d2}: #07131C;
  ${CSS_VARS.COLORS.BACKGROUND.d3}: #000000;
  ${CSS_VARS.COLORS.BACKGROUND.gradient}: transparent linear-gradient(180deg, ${COLORS.BACKGROUND.d3} 0%, ${COLORS.BACKGROUND.d1} 100%);
  ${CSS_VARS.COLORS.GRAY.d1}: #CACCD5;
  ${CSS_VARS.COLORS.GRAY.d2}: #838996;
  ${CSS_VARS.COLORS.WARNING}: #FEC132;
  ${CSS_VARS.COLORS.INFO}: #58C2D9;
  ${CSS_VARS.COLORS.DANGER}: #E64965;
  ${CSS_VARS.COLORS.SUCCESS}: #58D96D;
  ${CSS_VARS.COLORS.TEXT}: #FFFFFF;
`
export const CSSColorsVars = ({theme}) => theme === THEMES.DARK ? DarkThemeColors : LightThemeColors;
import styled, {css} from "styled-components";
import {MEDIA_DIAMOND, MEDIA_GOLD, MEDIA_PLATINUM, MEDIA_SILVER} from "../../../styles/responsive";
import {COLORS} from "../../../common/constants";
import {ComponentPropsWithRef, forwardRef} from "react";

const CSSInvalid = css`
  & input[type='date']{
    &::-webkit-datetime-edit-text { 
      color:${COLORS.DANGER};
    }
    &::-webkit-calendar-picker-indicator { 
      background-color: ${COLORS.GRAY.d2};
      filter: hue-rotate(120deg) contrast(200%) brightness(100%);
    }
  }
  & input {
    color: ${COLORS.DANGER};
    border-color: ${COLORS.DANGER};

    &:focus {
      border-color: ${COLORS.DANGER};

      & ~ svg {
        & .pathOne {
          stroke: ${COLORS.BACKGROUND.d1};
        }

        & .pathTwo {
          stroke: ${COLORS.BACKGROUND.d1};
        }
      }
    }

    &:not(:placeholder-shown) {
      & ~ svg {
        & .pathOne {
          stroke: ${COLORS.BACKGROUND.d1};
        }

        & .pathTwo {
          stroke: ${COLORS.BACKGROUND.d1};
        }
      }
    }
  }



`
const CSSNoSvg = css`
  & input {
    padding-left: 1em;
    padding-right: 1em;
  }
`;
const Bronze = css`
  position: relative;
  height: fit-content;
  font-size: 13px;

  & .vs-label {
    margin-bottom: 0.8333em;
    color: ${COLORS.GRAY.d2};
    font-size: 1.385em;
  }

  & input[type='date']{
    &::-webkit-datetime-edit { padding: 1em; }
    &::-webkit-datetime-edit-text { color:${COLORS.GRAY.d2}; padding: 0 0.7em; }
    &::-webkit-calendar-picker-indicator { 
      background-color: ${COLORS.GRAY.d2};
      border-radius: 0.5em;
      font-size: 1.1em;
      cursor:pointer;
    }
  }

  & input {
    font-size: inherit;
    display: flex;
    align-items: center;
    height: 3.385em;
    width: 100%;
    border: 2px solid ${COLORS.BACKGROUND.d1};
    border-radius: 0.3846em;
    background: ${COLORS.BACKGROUND.d2};
    color: ${COLORS.TEXT};
    transition: border-color 200ms ease-out;
    letter-spacing: 0.02em;
    padding-left: 3.385em;
    &::placeholder {
      color: ${COLORS.GRAY.d2};
    }


    &:not(:placeholder-shown) {
      & ~ svg {
        & .pathOne {
          stroke: ${COLORS.SUCCESS};
        }

        & .pathTwo {
          stroke: ${COLORS.TEXT};
        }
      }
    }

    &:focus {
      border-color: ${COLORS.PRIMARY.d3};

      & ~ svg {
        & .pathOne {
          stroke: ${COLORS.PRIMARY.d3};
        }

        & .pathTwo {
          stroke: ${COLORS.TEXT};
        }
      }
      &::-webkit-datetime-edit-text { 
        color:${COLORS.PRIMARY.d5};
      }
      &::-webkit-calendar-picker-indicator { 
        filter: hue-rotate(260deg) contrast(200%) brightness(150%);
      }
    }
  }


  & svg {
    width: 1.385em;
    height: 1.385em;
    stroke: ${COLORS.BACKGROUND.d1};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    & .pathOne, & .pathTwo {
      transition: all 300ms ease-out;
    }

    [dir = 'ltr'] & {
      left: 1.538em;
    }

    [dir = 'rtl'] & {
      right: 1.538em;
    }
  }

  ${({errorMessage}: InputTextProps) => errorMessage && CSSInvalid}
  & input:-webkit-autofill ~ svg {
    & .pathOne, & .pathTwo {
      stroke: ${COLORS.BACKGROUND.d2};
    }
  }
  & small {
    color: ${COLORS.DANGER};
    padding-left: 0.538em;
    margin-top: 0.538em;
    letter-spacing: 0.02em;
    display: block;
  }

  ${({hasIcon}: InputTextProps) => !hasIcon && CSSNoSvg}
`

const Silver = css`

`

const Gold = css`

`

const Platinum = css`

`

const Diamond = css`

`

type InputTextProps =
	{ errorMessage?: string, label?: string, hasIcon?: boolean }
	& ComponentPropsWithRef<"input">

const SInputText = styled.div<InputTextProps>`
  ${Bronze}
  ${MEDIA_SILVER`${Silver}`}
  ${MEDIA_GOLD`${Gold}`}
  ${MEDIA_PLATINUM`${Platinum}`}
  ${MEDIA_DIAMOND`${Diamond}`}
`

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(({
	                                                                       children,
	                                                                       label,
	                                                                       className,
	                                                                       hasIcon = true,
	                                                                       errorMessage,
	                                                                       ...props
                                                                       }: InputTextProps, ref) => {
	return (
		<SInputText className={className} errorMessage={errorMessage} hasIcon={hasIcon}>
			{label && <div className="vs-label">{label}</div>}
			<input {...props} ref={ref}/>
			{children}
			{errorMessage && <small>{errorMessage}</small>}
		</SInputText>
	)
})











import {Property} from "../../types/types";

const defaultProperty: Property = {
	"_id": "",
	"installments": [],
	"unitDetails": {
		"unitAreaSize": NaN,
		"roomsCount": NaN,
		"plotAreaSize": NaN,
		"bathRoomsCount": NaN,
		"gardenArea":NaN,
		"roofArea":NaN,
		"finishingStatus": "finished"
	},
	"cashPriceEnabled": false,
	"installmentsEnabled": false,
	"rentEnabled": false,
	"cashPrice": NaN,
	"gallery": [],
	"youtubeVideoUrl":"",
	"virtualRoomUrl":"",
	"isContactFromDeveloper": false,
	"isContactFromProject": false,
	"isArchived": false,
	"isPublished": false,
	"wasPublished": false,
	"name": "",
	"priority":undefined,
	"description": "",
	"translation": {
		"ar": {
			"name": "",
			"description": ""
		}
	},
	"developer": {
		"_id": "",
		"name": "",
		"contactPerson": {
			"phone": "",
			"email": "",
			"name": "",
			"jobTitle": ""
		},
		"translation": {
			"ar": {
				"name": "",
				"description": ""
			}
		},
	},
	"project": {
		"_id":'',
		"cover":'',
		"description":'',
		"logo":'',
		"name":'',
		"masterPlan": "",
		"translation": {
			"ar": {
				"name": "",
				"description": ""
			}
		},
		"country":{
			"_id":"",
			"name":"",
			"translation":{
				"ar":{
					"name":""
				}
			}
		},
		"city":{
			"_id":"",
			"name":"",
			"translation":{
				"ar":{
					"name":""
				}
			}
		},
		"district":{
			"_id":"",
			"name":"",
			"translation":{
				"ar":{
					"name":""
				}
			}
		}
	},
	"category": {
		'_id':"",
		'name':"",
		"translation":{
			"ar": {
				"name": "",
			}
		},
		"created_at":"",
		"updated_at":"",
		"isArchived":false,
		"__v":0,
	},
	"subCategory": {
		'_id':"",
		"category":"",
		'name':"",
		"translation":{
			"ar": {
				"name": "",
			}
		},
		"created_at":"",
		"updated_at":"",
		"isArchived":false,
		"__v":0,
	},
	"propertyType": "",
	"amenities": [],
	"cardImage": "",
	"unitPlans": [],
	"unitAreas": [],
	"locationsOnMasterPlan": [],
	"created_at": "",
	"updated_at": "",
	"__v": 0,
	"tag":{
		"text":"",
		"expirationDate":"",
		"translation":{
			"ar":{
				"text":""
			}
		},
	},
	"searchKeywords":""
};
type SetPropertyAction = { type: "SET_PROPERTY", payload: Property };
type UpdatePropertyAction = { type: "UPDATE_PROPERTY", payload: Partial<Property> }
type PropertyAction = SetPropertyAction | UpdatePropertyAction;

export const propertyActions = {
	setProperty(property: Property): SetPropertyAction {
		return {
			type: "SET_PROPERTY",
			payload: property,
		}
	},
	updateProperty(payload: Partial<Omit<Property, "_id" | "updated_at" | "wasPublished" | "isArchived" | "__v" | "created_at">>): UpdatePropertyAction {
		return {
			type: "UPDATE_PROPERTY",
			payload
		}
	}
}

export const propertyReducer = (property = defaultProperty, action: PropertyAction): Property => {
	switch (action.type) {
		case "SET_PROPERTY":
			return action.payload;
		case "UPDATE_PROPERTY":
			return {...property, ...action.payload}
		default:
			return property;
	}
}
import { Project } from './../../types/types';

const defaultProject: Project = {
	"_id": "",
	"isPriceChangeable":false,
    "location": {
		"type": "Point",
		"coordinates": [
			0,
			0
		]
	},
	"gallery": [],
	"cardImage":"",
	"propertiesCountToNotify":0,
    "brands":[],
	"isContactFromDeveloper": false,
	"isArchived": false,
	"isPublished": false,
	"wasPublished": false,
	"name": "",
	"description": "",
	"sort_by":"",
	"priority":undefined,
	"translation": {
		"ar": {
			"name": "",
			"description": ""
		}
	},
	"country":{
		"_id": "",
		"name": "",
		"translation": {
			"ar": {
				"name": ""
			}
		},
	},
	"city":{
		"_id": "",
		"name": "",
		"translation": {
			"ar": {
				"name": ""
			}
		},
	},
	"district":{
		"_id": "",
		"name": "",
		"translation": {
			"ar": {
				"name": ""
			}
		},
	},
    "logo":"",
    "cover": "",
    "brochure":[],
    "masterPlan": "",
	"expirationDate": "",
	"features":[{
            "starred":false,
			"_id": "",
			"media":"",
			"feature": {
                    "_id": "",
                    "isArchived": false,
					"icon":"",
                    "name": "",
                    "created_at": "",
                    "updated_at": "",
                    "__v": 0,
                    "usable": false,
            },
			"created_at": "",
			"updated_at": ""
		},
    ],
	"assignedTo": {
		"_id": "",
		"name": "",
		"username": ""
	},
	"developer": {
		"_id": "",
		"name": "",
        "contactAndLocation": {
            "phone": "",
            "email": "",
            "whatsapp": "",
        },
		"contactPerson": {
			"phone": "",
			"email": "",
			"name": "",
			"jobTitle": "",
            "whatsapp":"",
		},
	},
    "contact": {
		"phone": "",
		"email": "",
		"whatsapp": ""
	},
	"otherAssignedTo": "",
	"created_at": "",
	"updated_at": "",
	"__v": 0,
	"tag":{
		"text":"",
		"expirationDate":"",
		"translation":{
			"ar":{
				"text":""
			}
		},
	},
    "properties":[
        {
            "_id":"",
            "name":"",
			"description":"",
			"translation": {
				"ar": {
					"name": "",
					"description":""
				}
			},
            "created_at":"",
			"cardImage":"",
            "locationsOnMasterPlan":[],
            "category":{
                "_id": "",
                "isArchived": false,
                "name": "",
                "created_at": "",
                "updated_at": "",
                "__v": 0,
            },
            "subCategory":{
                "category": "",
                "_id": "",
                "isArchived": false,
                "name": "",
                "created_at": "",
                "updated_at": "",
                "__v": 0,
            },
        },
    ],
};
type setProjectAction = { type: "SET_PROJECT", payload: Project };
type UpdateProjectAction = { type: "UPDATE_PROJECT", payload: Partial<Project> }
type ProjectAction = setProjectAction | UpdateProjectAction;

export const projectActions = {
	setProject(project: Project): setProjectAction {
		return {
			type: "SET_PROJECT",
			payload: project,
		}
	},
	updateProject(payload: Partial<Omit<Project, "_id" | "updated_at" | "wasPublished" | "isArchived" | "__v" | "created_at">>): UpdateProjectAction {
		return {
			type: "UPDATE_PROJECT",
			payload
		}
	}
}

export const projectReducer = (project = defaultProject, action: ProjectAction): Project => {
	switch (action.type) {
		case "SET_PROJECT":
			return action.payload;
		case "UPDATE_PROJECT":
			return {...project, ...action.payload}
		default:
			return project;
	}
}
import { GetOneDeveloper } from './../../types/types';

const defaultDeveloper: GetOneDeveloper = {
    "assignedTo": {"_id": "", "name": ""},
    "description": "",
	"translation":{
		"ar":{
			"name":"",
			"description":"",
		},
	},
    "contactAndLocation": {"phone": "", "email": "", "whatsapp": ""},
    "contactPerson": {"phone": "", "email": "", "whatsapp": "", "name": "", "jobTitle": ""},
    "cover": "",
    "created_at": "",
	"cardImage":"",
    "logo": "",
    "isArchived": false,
    "isPublished": false,
    "name": "",
    "projects": [],
    "updated_at": "",
    "wasPublished": false,
    "__v": 0,
    "_id": "",
}
type setDeveloperAction = { type: "SET_DEVELOPER", payload: GetOneDeveloper };
type UpdateDeveloperAction = { type: "UPDATE_DEVELOPER", payload: Partial<GetOneDeveloper> }
type DeveloperAction = setDeveloperAction | UpdateDeveloperAction;

export const developerActions = {
	setDeveloper(developer: GetOneDeveloper): setDeveloperAction {
		return {
			type: "SET_DEVELOPER",
			payload: developer,
		}
	},
	updateDeveloper(payload: Partial<Omit<GetOneDeveloper, "_id" | "updated_at" | "wasPublished" | "isArchived" | "__v" | "created_at">>): UpdateDeveloperAction {
		return {
			type: "UPDATE_DEVELOPER",
			payload
		}
	}
}

export const developerReducer = (developer = defaultDeveloper, action: DeveloperAction): GetOneDeveloper => {
	switch (action.type) {
		case "SET_DEVELOPER":
			return action.payload;
		case "UPDATE_DEVELOPER":
			return {...developer, ...action.payload}
		default:
			return developer;
	}
}
import styled, { css } from "styled-components";
import { COLORS } from "../../common/constants";
import { ButtonMain } from "../../components/form-components/buttons/ButtonMain";
import VerticalLogo from "../../components/logo/VerticalLogo";
import { MenuSection } from "../../components/menu/MenuSection";
import { PageIcon } from "../../components/page-icon/PageIcon";
import { SecondaryPageHeader } from "../../components/secondery-page-header/SecondaryPageHeader";
import { FormWelcomeMessage } from "../../components/sign-in-components/FormWelcomeMessage";
import { SignInForm } from "../../components/sign-in-components/SignInForm";
import { MEDIA_DIAMOND, MEDIA_GOLD, MEDIA_PLATINUM, MEDIA_SILVER } from "../../styles/responsive";

const Bronze = css`
& .vs-form-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-top: 300px;
  & .vs-welcome-component{
    width: 420px;
    margin-bottom: 60px;
  }
  & .vs-sign-in-form{
    width: 450px;
    padding: 20px;
    background-color: ${COLORS.BACKGROUND.d2};
    border-radius: 5px;
    overflow: hidden;
  }
}

[dir = 'ltr'] & {

}

[dir = 'rtl'] & {

}

`

const Silver = css`

`

const Gold = css`

`

const Platinum = css`

`

const Diamond = css`

`

const SSign = styled.div`
  ${Bronze}
  ${MEDIA_SILVER`${Silver}`}
  ${MEDIA_GOLD`${Gold}`}
  ${MEDIA_PLATINUM`${Platinum}`}
  ${MEDIA_DIAMOND`${Diamond}`}
`

const SignPage = () => {
    return (
      <SSign>
          <div className="vs-form-wrapper">
            <FormWelcomeMessage className='vs-welcome-component'/>
            <div className="vs-right-side">
              <SignInForm className='vs-sign-in-form'/>
            </div>
          </div>
      </SSign>
    )
};

export default SignPage;
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import './App.css';
import { ObjectToString, StringToObject } from "./common/constants";
import { Modal } from './components/modal/Modal';
import SignPage from "./pages/sign/SignPage";
import Routes from "./routes/routes";
import {changeLanguageAction, logoutUserAction, setUserAction} from "./store/actions";
import GlobalStyles from "./styles/glopbal-styles";
import { ReduxState } from "./types/types";


function App() {
  const [dir,lang] = useSelector(({preferences}:any) => [preferences.lang.dir,preferences.lang.name]);
  const user = useSelector(({user}:ReduxState) => user)
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.dir = dir;
  }, [dir]);

  useEffect(() => {
    // dispatch(fetchDictionaryAction(lang))
    dispatch(changeLanguageAction(lang))
  },[]);

  useEffect(() => {
    if (localStorage.getItem("USER_INFO")) {
        if (localStorage.getItem('EXPIRY_TIME')){
        let date = Date.now();
          if (date.toString() > localStorage.getItem('EXPIRY_TIME')!){
            console.log('expired');
            dispatch(logoutUserAction());
            localStorage.clear();
            return
          }
        dispatch(setUserAction(StringToObject(localStorage.getItem("USER_INFO"))))
        }
    }
  }, [])
  useEffect(() => {
    localStorage.setItem("USER_INFO",ObjectToString(user._id,user.token,user.name))
  }, [user])

  return (
      <div className="App">
        <GlobalStyles/>
        {user.token ? <BrowserRouter>
          <Routes/>
        </BrowserRouter> : <SignPage/>}
        <Modal />
      </div>
  );
}

export default App;

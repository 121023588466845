//enums
export type theme = "light" | "dark";
export type language = "ar" | "en" | "ar-eg" | "ar-em" | "en-us";
export type queryLang = language | "all";
// export type languageKeys = Exclude<language,"en">
export type languageKeys = "ar";
export type direction = "rtl" | "ltr";
export type casing = "proper-case" | "upper-case" | "lower-case";
export type action = "CHANGE_THEME" | "CHANGE_LANGUAGE" | "CHANGE_DICTIONARY" | "FETCH_DICTIONARY";
export const getAction = (action: action) => action
//redux state
export type preferences = {
	lang: {
		name: language,
		dir: direction,
		preserveCasing: boolean
	},
	theme: theme,
};
export type dictionary = {
	[key: string]: string
};
export type ui = {
	modal: {
		isActive: boolean,
		content: JSX.Element | null,
		variant: string,
	}
};
export type ReduxState = {
	preferences: preferences,
	ui: ui,
	dictionary: dictionary,
	user: UserReducerState,
	property: Property,
	project:Project,
	developer:GetOneDeveloper,
};
//redux actions
export type changeThemeActionType = {
	type: "CHANGE_THEME",
	payload: theme,
};
export type changeLanguageActionType = {
	type: "CHANGE_LANGUAGE",
	payload: language
};
export type changeDictionaryActionType = {
	type: "CHANGE_DICTIONARY",
	payload: dictionary,
};
export type fetchDictionaryActionType = {
	type: "FETCH_DICTIONARY",
	payload: language
};
export type showModalActionType = {
	type: "SHOW_MODAL",
	payload: JSX.Element | null
};
export type showCreateModalActionType = {
	type: "SHOW_CREATE_MODAL",
	payload: JSX.Element | null
};
export type showImageModalActionType = {
	type: "SHOW_IMAGE_MODAL",
	payload: JSX.Element | null
};
export type hideModalActionType = {
	type: "HIDE_MODAL",
};
//#region User reducer actions
export type setUserActionType = {
	type: "SET_USER",
	payload: UserReducerState
}
export type logoutUserActionType = {
	type: "LOGOUT_USER"
}
//#endregion

//redux reducers actions
export type preferencesActions = changeThemeActionType | changeLanguageActionType;
//nav bar types
export type navbarLink = '/' | '/saved' | '/explore' | '/notifications' | '/menu' | 'none';
export type iconSize = "normal" | "large";

//form types
export type selectOption = { value: string, label: string } | null

//map types
export type coordinates = { x: number, y: number }

// General types
export type Id = string

// Backend Data Types
export type UserRole = {
	title: string,
	color: string
}
export type AdminUser = {
	id: string,
	userName: string
	email: string
	name: string
	role: UserRole
	projects: {}[]
}
export type Pagination = {
	pagination: boolean,
	page?: number,
	limit?: number,
}
type AssignedToObj = {
	_id: Id,
	name: string
}

export type RoleColors = "grey" | "green" | "yellow" | "blue" | "red";

//#region User Api Types
export type UserReducerState = {
	_id:string,
	name:string,
	token:string,
}
export type User = {
    _id: Id,
    phone:string,
    name:string,
    isArchived:boolean,
    username:string,
    email:string,
    created_at:string,
    roles:string[],
    token:string,
    profileImage:string,
    loggedViaFacebook:boolean,
    loggedViaGoogle:boolean,
    loggedViaApple:boolean,
    notifications:{
        newListings:boolean,
        recommendations:boolean,
        projectUpdate:boolean,
        extra:boolean
    },
    whatsapp:string,
    password:string,
    emailOrPhone:string,
    role:Id
    type:string,
    fcmToken: string,
	address:string,
	country: string,
	countryCode: string,
	userRole: Role & { permissions: Omit<Permission, 'translation'>[] },
	favoriteDevelopers:Id[],
	favoriteProjects:Id[],
	favoriteProperties:Id[],
	language: string,
	playVideosOnWifiOnly: boolean,
}
// export type GetOneUser = User
export type UserLoginOrRegisterResponse = User
export type CreateUser = Pick<User,'name'|'email'|'password'|'type'|'role'>
export type UpdateUser = Pick<User,'name'|'email'|'password'>
export type CreateUserResponse = Pick<User,'_id'>
export type GetOneUser = Omit<User,'fcmToken'|
	'language'|'role'|'emailOrPhone'|
	'password'|'favoriteDevelopers'|'favoriteProjects'|'favoriteProperties'>
export type GetAllUsers = GetOneUser[]
export type UserLogin = Pick<User,'emailOrPhone'|'password'|'type'>
export type UserLoginResponse = Omit<User,'language'|'password'|'type'|'userRole'|'emailOrPhone'|'role'>
export type UserLogoutResponse = {success:boolean,message:string}
// export type UserLoginResponse2 = {
//     _id: Id,
//     phone: string,
//     name: string,
//     isArchived: boolean,
//     username: string,
//     email: string,
//     created_at: string,
//     roles: [
//         string
//     ],
//     token: string,
//     profileImage: string,
//     loggedViaFacebook: boolean,
//     loggedViaGoogle: boolean,
//     loggedViaApple: boolean
// }
// export type GetAllUsers = Omit<User,'userRole'|'notifications'|'playVideosOnWifiOnly'|'whatsapp'|'address'|'country'|'language'>[]
//#endregion

//#region Developer Api Types
// Developer Types
type Developer = {
	name: string,
	isArchived: boolean,
	isPublished: boolean,
	wasPublished: boolean,
	_id: Id,
	description: string,
	logo: string,
	cardImage:string,
	cover: string,
	translation: {
		[key in languageKeys]: {
			name: string,
			description: string
		}
	},
	contactAndLocation: {
		phone: string,
		email?: string,
		whatsapp?: string,
		translation?: {
			[key in languageKeys]: {
				city: string,
				state: string
			}
		},
	},
	contactPerson: {
		phone: string,
		email?: string,
		whatsapp?: string,
		name: string,
		jobTitle: string
	},
	assignedTo: AssignedToObj,
	created_at: string,
	updated_at: string,
	__v: number,
	projects: Pick<Project,'name'|'_id'|'cardImage'|'description'|'isPublished'|'expirationDate'|'logo'> [],
}
export type CreateDeveloper = Pick<Developer, 'name' | 'contactPerson'>&{assignedTo:Id}
export type CreateDeveloperResponse =
	Pick<Developer, 'name' | 'logo' | 'cover' | 'cardImage' | 'description' | 'isPublished' | 'wasPublished' | 'contactAndLocation' | 'isArchived' | 'contactPerson' | '_id' | 'created_at' | 'updated_at' | '__v'>
	& {assignedTo:AssignedToObj}
export type UpdateDeveloper = Partial<Omit<Developer, 'created_at'|'updated_at'|'__v'|'projects'|'assignedTo'|'_id'|'wasPublished'|'isArchived'>&{assignedTo?:Id}>
export type UpdateDeveloperResponse = GetOneDeveloper
export type GetOneDeveloper = Developer
export type GetAllDevelopers = GetOneDeveloper[]
//#endregion

//#region Project Api Types
type TagObject = {
    text:string,
    expirationDate:string,
	translation: {
		[key in languageKeys]: {
			text: string,
		}
	},
}
// Project
export type PropertiesInProject = (Pick<Property, '_id' | 'description' | 'translation' | 'name' | 'cardImage' | 'locationsOnMasterPlan' | 'created_at'> & { category: Category, subCategory: SubCategory })
export type Project = {
	_id: Id,
	location: {
		type: "Point",
		coordinates: [
			number,
			number
		]
	},
	isPriceChangeable:boolean,
	gallery: string[],
	cardImage:string,
	brands: Brand[],
	propertiesCountToNotify:number,
	isContactFromDeveloper: boolean,
	isArchived: boolean,
	isPublished: boolean,
	wasPublished: boolean,
	name: string,
	description: string,
	translation?: {
		[key in languageKeys]: {
			name: string,
			description: string
		}
	},
	country:{
		_id:Id,
		name:string,
		translation: {
			[key in languageKeys]: {
				name: string,
			}
		},
	},
	city:{
		_id:Id,
		name:string,
		translation: {
			[key in languageKeys]: {
				name: string,
			}
		},
	},
	district:{
		_id:Id,
		name:string,
		translation: {
			[key in languageKeys]: {
				name: string,
			}
		},
	},
	logo: string,
	cover: string,
	brochure: string[],
	masterPlan: string,
	expirationDate: string,
	features: ProjectFeature[],
	assignedTo: {
		_id: Id,
		name: string,
		username: string
	},
	developer: {
		_id: Id,
		name: string,
		contactAndLocation: {
			phone: string,
			email: string,
			whatsapp: string,
			translation?: {
				[key in languageKeys]: {
					name: string
				}
			},
		},
		contactPerson: {
			phone: string,
			email: string,
			whatsapp: string,
			name: string,
			jobTitle: string,
			translation?: {
				[key in languageKeys]: {
					name: string
				}
			}
		},
	},
	contact?: {
		phone: string,
		email: string,
		whatsapp: string
	},
	sort_by:string,
	priority?:number,
	tag:TagObject,
	otherAssignedTo: Id,
	created_at: string,
	updated_at: string,
	__v: 0,
	properties: PropertiesInProject[]
}
export type CreateProject = Pick<Project, 'name'> & { developer: Id, assignedTo: Id,translation:{ar:{name:string}}}
export type CreateProjectResponse = Omit<Project, 'description' | 'translation' | 'developer'>
export type GetOneProject = Project
export type GetAllProjects = Project[]
export type UpdateProject = Partial<Omit<Project,'features'|'brands'|'country'|'city'|'district'| 'location' | 'wasPublished' | 'assignedTo' | 'isArchived'|'created_at'|'updated_at'> & { developer: Id,brands:Id[],features:{_id?:Id,starred:boolean,media?:string,feature:Id}[], assignedTo: Id,country:Id,city:Id,district:Id, location:[number,number] }>
//#endregion

//#region Project Features
export type ProjectFeature = {
	starred: boolean,
	_id?: Id,
	media: string,
	feature: Feature
	created_at?: string,
	updated_at?: string
}
export type ProjectFeature2 = {
	starred: boolean,
	_id?: Id,
	media: string,
	feature: string,
}
//#endregion

//#region Project Default Unit Areas
export type DefaultUnitArea = {
	_id:Id,
	name:string,
	color:RoleColors,
	translation: {
		[key in languageKeys]: {
			name: string,
		}
	},
	project: Id,
	created_at: string,
	updated_at: string,
	__v: number,
	isArchived: boolean,
	flooring:Floor[],
	walls:Wall[],
	doors:Door[],
	windows:Window[]
}

export type CreateDefaultUnitArea = Omit<DefaultUnitArea,'project'|'__v'|"_id"|"created_at"|"updated_at"|"isArchived"|"doors"|"flooring"|"walls"|"windows"> & {flooring:Id[],doors:Id[],windows:Id[],walls:Id[]}
export type CreateDefaultUnitAreaResponse = DefaultUnitArea
export type GetOneDefaultUnitAreas = DefaultUnitArea
export type GetAllDefaultUnitAreas = DefaultUnitArea[]
export type UpdateDefaultUnitArea = Partial<Omit<DefaultUnitArea,'project'|'__v'|"_id"|"created_at"|"updated_at"|"isArchived"|"doors"|"flooring"|"walls"|"windows"> & {flooring:Id[],doors:Id[],windows:Id[],walls:Id[]}>
//#endregion

//#region Project Default Buildings
export type DefaultBuilding = {
	title:string
	_id: Id,
	location: [number,number],
	isArchived: boolean,
	propertyNumber: number,
	floor: number,
	apartmentNumber: number,
	isSold: boolean,
	project: Id,
	created_at: string,
	updated_at: string,
	__v: number
}
export type CreateDefaultBuilding = Pick<DefaultBuilding,'location' | 'propertyNumber' | 'title' | 'apartmentNumber' | 'isSold' | 'floor'>
export type CreateDefaultBuildingResponse = DefaultBuilding
export type GetOneDefaultBuilding = DefaultBuilding
export type GetAllDefaultBuildings = DefaultBuilding[]
export type UpdateDefaultBuilding = CreateDefaultBuilding
//#endregion

//#region Features API types
// Feature =
export type Feature = {
	_id: string,
	isArchived: boolean,
	name: string,
	icon:string,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	created_at: string,
	updated_at: string,
	__v?: number,
	usable?: boolean
}
export type createFeature = Pick<Feature, 'name' | 'icon' | 'translation'>;
export type createFeatureResponse = Omit<Feature, 'translation'>;
export type GetOneFeature = Feature
export type GetAllFeatures = Feature[]
export type updateFeature = Pick<Feature, 'name' | 'icon' | 'translation'>;
export type updateFeatureResponse = Feature;
//#endregion

//#region Amenity Api types
export type PropertyAmenity = {
	_id?:Id,
	starred: boolean,
	media: string,
	amenity: Amenity,
	created_at?: string,
	updated_at?: string,
}
export type PropertyAmenity2 = {
	starred: boolean,
	_id?: Id,
	media: string,
	amenity: string,
}
export type Amenity = {
	_id: string,
	isArchived: boolean,
	name: string,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	created_at: string,
	updated_at: string,
	__v?: number,
	usable?: boolean
}
export type createAmenity = Pick<Amenity, 'name' | 'translation'>;
export type createAmenityResponse = Omit<Amenity, 'translation'>;
export type GetOneAmenity = Amenity
export type GetAllAmenities = Amenity[]
export type updateAmenity = Pick<Amenity, 'name' | 'translation'>;
export type updateAmenityResponse = Amenity;
//#endregion

//#region ProperyType Api types
type PropertyType = {
	_id: string,
	isArchived: boolean,
	name: string,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	created_at: string,
	updated_at: string,
	__v?: number,
}
export type createPropertyType = Pick<PropertyType, 'name' | 'translation'>;
export type createPropertyTypeResponse = Omit<PropertyType, 'translation'>;
export type GetOnePropertyType = PropertyType
export type GetAllPropertyTypes = PropertyType[]
export type updatePropertyType = Pick<PropertyType, 'name' | 'translation'>;
export type updatePropertyTypeResponse = PropertyType;
//#endregion

//#region brands API types
export type Brand = {
	_id: Id,
	usable: boolean,
	isArchived: boolean,
	name: string,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	created_at: string,
	updated_at: string,
	image: string,
	__v: number
}
export type CreateBrand = Pick<Brand, 'name' | 'translation' | 'image' | 'usable'>;
export type CreateBrandResponse = Omit<Brand, 'translation'>;
export type GetOneBrand = Brand
export type GetAllBrands = Brand[]
export type UpdateBrand = Partial<CreateBrand>;
export type UpdateBrandResponse = CreateBrandResponse;
//#endregion

//#region Floors Api Types
type Floor = {
	_id: Id,
	isArchived: boolean,
	name: string,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	created_at: string,
	updated_at: string,
	__v: number
}
export type CreateFloor = Pick<Floor, 'name' | 'translation'>
export type CreateFloorResponse = Omit<Floor, 'translation'>
export type GetOneFloor = Floor
export type GetAllFloors = Floor[]
export type UpdateFloor = CreateFloor
export type UpdateFloorResponse = Floor
//#endregion

//#region Doors Api Types
type Door = {
	name: string,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	isArchived: boolean,
	_id: Id,
	created_at: string,
	updated_at: string,
	__v: 0
}
export type CreateDoor = Pick<Door, 'name' | 'translation'>
export type CreateDoorResponse = Omit<Door, 'translation'>
export type GetOneDoor = Door
export type GetAllDoors = Door[]
export type UpdateDoor = CreateDoor
export type UpdateDoorResponse = Door
//#endregion

//#region Walls Api Types
type Wall = {
	name: string,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	isArchived: boolean,
	_id: Id,
	created_at: string,
	updated_at: string,
	__v: 0
}
export type CreateWall = Pick<Wall, 'name' | 'translation'>
export type CreateWallResponse = Omit<Wall, 'translation'>
export type GetOneWall = Wall
export type GetAllWalls = Wall[]
export type UpdateWall = CreateWall
export type UpdateWallResponse = Wall
//#endregion

//#region Window Api Types
type Window = {
	name: string,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	isArchived: boolean,
	_id: Id,
	created_at: string,
	updated_at: string,
	__v: 0
}
export type CreateWindow = Pick<Window, 'name' | 'translation'>
export type CreateWindowResponse = Omit<Window, 'translation'>
export type GetOneWindow = Window
export type GetAllWindows = Window[]
export type UpdateWindow = CreateWindow
export type UpdateWindowResponse = Window
//#endregion

//#region Properties Api Types
export type Property = {
	_id: Id,
	installments: Installment[],
	unitDetails: {
		unitAreaSize: number,
		roomsCount: number,
		plotAreaSize: number,
		bathRoomsCount: number,
		gardenArea:number,
		roofArea:number,
		finishingStatus: string
	},
	cashPriceEnabled: boolean,
	installmentsEnabled: boolean,
	rentEnabled: boolean,
	cashPrice: number,
	gallery: string[],
	youtubeVideoUrl?:string,
	virtualRoomUrl?:string,
	isContactFromDeveloper: boolean,
	isContactFromProject: boolean,
	isArchived: boolean,
	isPublished: boolean,
	wasPublished: boolean,
	name: string,
	description: string,
	translation: {
		[key in languageKeys]: {
			name: string,
			description: string,
		}
	},
	developer: Pick<Developer, '_id' | 'name' | 'contactPerson' | 'translation'>,
	project: Pick<Project,'_id'|'name'|'cover'|'logo'|'description' | "masterPlan" | "translation" | 'country' | 'city' | 'district'>,
	category: Category,
	subCategory: SubCategory,
	propertyType: Id,
	amenities: PropertyAmenity[],
	cardImage: string,
	priority?:number,
	unitPlans: UnitPlan[],
	unitAreas: UnitArea[],
	locationsOnMasterPlan: LocationOnMasterPlan[],
	created_at: string,
	updated_at: string,
	__v: number,
	tag:TagObject
	searchKeywords:string
}
export type UnitArea = {
	_id: Id,
	size: string,
	color: RoleColors,
	flooring: Floor[],
	walls: Wall[],
	doors: Door[],
	windows: Window[],
	name: string,
	translation: {
		[key in languageKeys]: {
			name: string
		}
	},
	created_at: string,
	updated_at: string
};
export type LocationOnMasterPlan = {
	buildingId:Id,
	location: [
		number,
		number
	],
	_id: Id,
	referenceNumber: string,
	floor: number,
	isSold: boolean,
	created_at: string,
	updated_at: string
};
export type UnitPlan = {
	_id: Id,
	image: string,
	locations: UnitPlanLocation[],
	created_at: string,
	updated_at: string
};
export type UnitPlanLocation = {
	location: [
		number,
		number
	],
	_id: Id,
	area: Id,
	created_at: string,
	updated_at: string
}
export type Installment = {
	payment: {
		amount: number,
		type: "monthly" | "quarterly" | "semi-annually" | "yearly" | "up-to"
	},
	downpayment: number,
	years: number,
	"_id": Id,
	"created_at": string,
	"updated_at": string
}
type UnitPlanPoint = { location: [number, number], _id: Id, area: Id, created_at: string, updated_at: string }
export type CreateProperty = Pick<Property, 'name' |'translation'> & { developer: Id, project:Id, category:Id, subCategory:Id }
export type CreatePropertyResponse = Omit<Property, 'translation'>
export type GetOneProperty = Property
export type GetAllProperties = GetOneProperty[]
export type UpdateProperty =
	Partial<Omit<Property, "_id" | "created_at" | "updated_at" | "__v" | "isArchived" | "wasPublished" | "unitAreas" | "locationsOnMasterPlan" | "unitPlans" | "amenities" | "installments" | "developer" | 'category' | 'subCategory'>>
	& { unitPlans?: UpdateUnitPlan[] }
	& { unitAreas?: (Omit<UnitArea, "_id" | "created_at" | "updated_at" | "flooring" | "walls" | "doors" | "windows"> & { _id?: Id, flooring:Id[], walls:Id[], doors:Id[], windows:Id[] })[] }
	& { locationsOnMasterPlan?: (Omit<LocationOnMasterPlan, "_id" | "created_at" | "updated_at"> & { _id?: Id })[] }
	& {installments?: (Omit<Installment, "_id" | "created_at" | "updated_at"> & { _id?: Id })[]}
	& { amenities?: { starred: boolean,media?: string,amenity: Id, _id?: Id }[] }
	& {developer?: Id}
	& {category?: Id}
	& {subCategory?: Id}
export type UpdateUnitPlan = (Pick<UnitPlan, "image"> & { _id?: Id } & { locations: (Pick<UnitPlanPoint, "location" | "area"> & { _id?: Id })[] })
export type UpdatePropertyResponse = Property
//#endregion

//#region Permissions Api Types
export type Permission = {
	_id: Id,
	isArchived: boolean,
	name: `${PermissionsName}.${PermissionsType}`,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	addedBy: string,
	created_at: string,
	updated_at: string,
	__v: number
};
export type GetAllPermissions = Permission[]
export type PermissionsName =
	'developer'
	| 'amenities'
	| 'properties'
	| 'walls'
	| 'windows'
	| 'doors'
	| 'floors'
	| 'projects'
	| 'categories'
	| 'subCategories'
	| 'brands'
	| 'features'
	| 'roles'
export type PermissionsType = 'view' | 'add' | 'edit' | 'delete';
//#endregion

//#region Roles Api Types
export type Role = {
	_id: Id,
	permissions: Id[],
	isArchived: false,
	name: string,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	color: RoleColors,
	addedBy: Id,
	created_at: string,
	updated_at: string,
	__v: number
}
export type CreateRole = Pick<Role, 'name' | 'permissions' | 'color'>
export type CreateRoleResponse = Omit<Role, 'translation'>
export type GetOneRole = Role
export type GetAllRoles = Role[]
export type UpdateRole = CreateRole
export type UpdateRoleResponse = Role
//#endregion

//#region Categories Api Types
export type Category = {
	_id: Id,
	isArchived: boolean,
	name: string,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	created_at: string,
	updated_at: string,
	__v: number
}
export type CreateCategory = Pick<Category, 'name' | 'translation'>
export type CreateCategoryResponse = Omit<Category, 'translation'>
export type GetOneCategory = Category
export type GetAllCategories = Category[]
export type UpdateCategory = CreateCategory
export type UpdateCategoryResponse = Category
//#endregion

//#region Sub-Categories Api Types
export type SubCategory = {
	category: Id,
	_id: Id,
	isArchived: boolean,
	name: string,
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	created_at: string,
	updated_at: string,
	__v: number
}
export type CreateSubCategory = Pick<SubCategory, 'name' | 'translation'>
export type CreateSubCategoryResponse = Omit<SubCategory, 'translation'>
export type GetOneSubCategory = SubCategory
export type GetAllSubCategories = SubCategory[]
export type UpdateSubCategory = CreateSubCategory
export type UpdateSubCategoryResponse = SubCategory
//#endregion

//#region Countires Api Types
type Country = {
	_id: Id;
	isArchived: boolean;
	name: string;
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	created_at: string;
	updated_at: string;
	__v: number;
};
export type CreateCountry = Pick<Country, 'name' | 'translation'>
export type CreateCountryResponse = Omit<Country, 'translation'>
export type GetOneCountry = Country
export type GetAllCountries = Country[]
export type UpdateCountry = CreateCountry
export type UpdateCountryResponse = Country
//#endregion

//#region Cities Api Types
type City = {
	_id: Id;
	isArchived: false;
	name: string;
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	country: Id;
	created_at: string;
	updated_at: string;
	__v: number;
};
export type CreateCity = Pick<City, 'name' | 'translation'>
export type CreateCityResponse = Omit<City, 'translation'>
export type GetOneCity = City
export type GetAllCities = City[]
export type UpdateCity = CreateCity
export type UpdateCityResponse = City
//#endregion

//#region District Api Types
type District = {
	_id: Id;
	isArchived: boolean;
	name: string;
	translation?: {
		[key in languageKeys]: {
			name: string
		}
	},
	location: {
		type:string,
		coordinates:number[]
	};
	city: Id;
	created_at: string;
	updated_at: string;
	__v: number;
};
export type CreateDistrict = (Pick<District, 'name' | 'translation'>& { location:number[] })
export type CreateDistrictResponse = Omit<District, 'translation'>
export type GetOneDistrict = District
export type GetAllDistricts = District[]
export type UpdateDistrict = CreateDistrict
export type UpdateDistrictResponse = District
//#endregion

//#region Contacts Api Type
type Contact = {
	_id:Id,
	user?: {
		name:string,
		email:string,
		phone:string,
		countryCode:string,
		_id:Id,
		username:string,
		profileImage:string
	},
	email?:string,
	name?:string,
	phone?:string,
	developer?: Pick<Developer, '_id'|'name'|'contactPerson'|'assignedTo'|'contactAndLocation'|'translation'|'logo'>
	project?: Pick<Project, '_id'|'cardImage'|'isContactFromDeveloper'|'name'|'translation'
		|'city'|'country'|'district'|'description'|'cover'|'logo'|'masterPlan'|'contact'>
	property?: Pick<Property, '_id'|'unitDetails'|'youtubeVideoUrl'|'cashPriceEnabled'
			|'installmentsEnabled'|'rentEnabled'|'cashPrice'|'gallery'|'isArchived'|'isPublished'
			|'wasPublished'|'name'|'translation'|'amenities'|'installments'|'unitPlans'|'unitAreas'
			|'locationsOnMasterPlan'|'created_at'|'updated_at'|'__v'|'cardImage'|'description'>
		&{category:Id,subCategory:Id,project:Id,developer:Id}
	created_at: string,
	updated_at: string,
	__v: number
	isArchived: boolean,
	actions?:string[]
}
export type CreateContact = {developer:Id, project?:Id, property?:Id }
export type CreateContactResponse = Pick<Contact, '_id'|'isArchived'|'user'|'created_at'|'updated_at'|'__v'> & {developer:Id, project?:Id, property?:Id }
export type GetOneContact = Contact
export type GetAllContacts = Contact[]
//#endregion

//#region Notification Api Type
export type CreateNotification = {
	title: string,
	message: string,
	arTitle: string,
	arMessage: string,
	from: string,
	type: string
}
export type CreateNotificationResponse = {
	success: boolean,
	message: string
}
//#endregion
import { setUserActionType, logoutUserActionType } from './../../types/types';

const defaultUserState = {
    _id:'',
    name:'',
    token:'',
}

export const userReducer = (userState = defaultUserState, action:setUserActionType|logoutUserActionType) => {
    if (action.type === 'SET_USER') {
        return {
            _id: action.payload._id,
            name: action.payload.name,
            token: action.payload.token,
        }
    }
    if (action.type === 'LOGOUT_USER') {
        return {
            _id: "",
            name: "",
            token: "",
        }
    }
    return userState
}

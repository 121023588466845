const EmailIcon = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 67.34" {...props}>
			<g data-name="Layer 2" fill="none" strokeMiterlimit={10} strokeWidth={4}>
				<path className='pathOne' d="M2 2h100v63.34H2z"/>
				<path
					d="M89.35 11L61.22 37.41a13.42 13.42 0 01-18.36 0L14.72 11M15.09 56.27l25.85-20.73M89.69 56.27L63.84 35.54"
					className='pathTwo'
				/>
			</g>
		</svg>
	)
};

export default EmailIcon;

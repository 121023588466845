const SecurityIcon = props => {
	
	return (
		<svg
			width={116}
			height={140}
			viewBox="0 0 116 140"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10.107 39.732h96.086l6.667 6.666v83.58l-6.667 6.667H10.107l-6.667-6.667v-83.58l6.667-6.666zM28.922 39.732V16.645L42.255 3.312h33.592l13.334 13.333v11.713"
				className='pathOne'
				strokeWidth={5.333}
				strokeMiterlimit={10}
			/>
			<path
				d="M79.534 65.826a55.275 55.275 0 00-42.768 0v33.935l2.448 4.556 15.92 10.538h6.032l15.92-10.538 2.448-4.556V65.826z"
				className='pathTwo'
				strokeWidth={5.333}
				strokeMiterlimit={10}
			/>
		</svg>
	)
};

export default SecurityIcon;

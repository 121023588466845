import {all, takeEvery, takeLatest, takeLeading} from "redux-saga/effects";
import {fetchDictionaryWorker, langWorker} from "./workers";
import {getAction} from "../../types/types";


//saga watchers
function* langWatcher(){
	yield takeEvery(getAction("CHANGE_LANGUAGE"), langWorker);
}

function* fetchDictionaryWatcher(){
	yield takeLatest(getAction("FETCH_DICTIONARY"),fetchDictionaryWorker);
}


//combine watchers into root watcher
export default function* Watchers(){
	yield all([langWatcher(), fetchDictionaryWatcher()]);
}
